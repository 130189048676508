<template>
    <div>
        <!-- <v-container> -->
        <!-- <div data-aos="fade-right">
                <h1 class="bigtitle">{{ $t('whereweare.title') }}</h1>
            </div> -->

        <v-card style="" :color="card_positive">
            <div class="text-center pt-4 pb-8" :style="textColor">

                <div class="d-flex justify-center">
                    <v-img data-aos="fade-in" data-aos-delay="200" class="" :max-width="image_size" alt="Logo"
                        src="@/assets/imgs/pinmap_01.png" />
                </div>
                <h2 class="" :style="title_size">
                    {{ $t('whereweare.title') }}
                </h2>

                <!-- <h3 class="pb-4 box-subtitle underlined">
                    {{ $t('home.ourtarget.subtitle') }}
                </h3> -->


                <div class="d-flex flex-column justify-center align-center px-2 ">
                    <p class=" limit-p-width">
                        {{ $t('whereweare.desc_1') }}
                    </p>
                    <p class=" limit-p-width">
                        {{ $t('whereweare.desc_2') }}
                    </p>
                    <p class=" limit-p-width">
                        {{ $t('whereweare.desc_3') }}
                    </p>

                    <!-- <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2850.7858334638704!2d7.8212912508002255!3d44.396517911962405!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12cd6345f143dadb%3A0x7a0a6efa70abc173!2sAVMSquare%20Informatica!5e0!3m2!1sit!2sit!4v1647351234495!5m2!1sit!2sit"
                        width="100%" height="450" style="border: 0" allowfullscreen="" loading="lazy"></iframe> -->

                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2847.028167316577!2d7.852936615524567!3d44.47359537910173!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47886b9a6cedd3dd%3A0xe4792d5abb830051!2sConsorzio%201980!5e0!3m2!1sen!2sit!4v1666703132772!5m2!1sen!2sit"
                        width="100%" :height="mapHeight" style="border:0;" allowfullscreen="" loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>



            </div>
        </v-card>


    </div>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init()
export default {
    props: {},
    components: {},
    metaInfo: {
        title: 'Dove siamo',
    },
    data: () => ({

    }),
    methods: {},
    mounted() { },
    created() { },
    computed: {
        textColor() {
            if (this.isDark) {
                return 'color: #ffffff'
            } else {
                return 'color: #0e0e0e'
            }
        },

        mapHeight() {
            if (this.isSmallMobile) {
                return 400
            } else {
                return 600
            }
        },

        image_size() {
            if (this.isSmallMobile) {
                return 80
            } else {
                return 100
            }
        },
        title_size() {
            if (this.isSmallMobile) {
                return 'font-size: 2.4rem'
            } else {
                return 'font-size: 3.2rem'
            }
        }
    },
    watch: {}
}
</script>

<style scoped>
.limit-p-width {
    max-width: 540px;
}
</style>
